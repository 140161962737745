<style scoped>
div#timeline-embed {
  height: 600px;
}
</style>
<template>
  <div>
    <spinner />
    <Header />

    <!-- Page Title
		============================================= -->
    <section id="page-title">
      <div class="container clearfix">
        <hr class="line" />
        <h1>
          Book of Projects <br />
          Transformasi & Digital
        </h1>
        <span>Holding BUMN Farmasi</span>
      </div>
    </section>

    <!-- #page-title end -->

    <!-- Content
		============================================= -->
    <section id="content" class="">
      <div class="content-wrap">
        <div class="container clearfix">
          <div
            id="shop"
            class="mt-4 shop row grid-container gutter-50"
            data-layout="fitRows"
          >
            <!-- ============================== 1 -->
            <div
              class="product col-md-4 col-sm-6 col-12"
              v-for="item in projects"
              :key="item.id"
            >
              <div class="grid-inner">
                <div class="product-image h-translate-y all-ts">
                  <router-link
                    :to="/projects/ + item.id"
                    v-if="item.Cover.formats.thumbnail.url"
                  >
                    <img :src="item.Cover.formats.thumbnail.url" alt="Image 1"
                  /></router-link>
                </div>
                <div class="product-desc">
                  <h3 class="my-0">
                    <router-link :to="/projects/ + item.id">
                      <a class="text-dark">{{ item.Name }}</a>
                    </router-link>
                  </h3>
                  <!-- <span>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                  </span> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- #content end -->
    <div class="divider divider-center"><i class="icon-circle"></i></div>

    <!-- <div class="container timeline-title">
      <h4 class="timeline-title">Timeline</h4>
    </div> -->

    <div class="container clearfix text-center timeline-title">
      <h1>Milestone Project Unggulan</h1>
      <hr class="line" />
    </div>
    <div id="timeline-landing" style="width: 100%; height: 800px"></div>
    <Footer />
  </div>
</template>

<script>
import Slider from '@/components/Slider.vue';
import Header from '../components/header.vue';
import Footer from '../components/Footer.vue';
import Spinner from '@/components/spinner';
import { Timeline } from '@knight-lab/timelinejs';
import '@knight-lab/timelinejs/dist/css/timeline.css';

export default {
  components: { Slider, Header, Footer, Spinner },
  name: 'Landing',
  data() {
    return {
      projects: {},
      timeline: [],
      dataTimeline: {
        events: [],
      },
      options: {
        font: 'georgia-helvetica',
        initial_zoom: 2,
      },
    };
  },
  mounted: function () {},

  async created() {
    await this.getProjects();
    await this.getTimeline();
    await this.populateData();
  },
  methods: {
    async getProjects() {
      let _ = this;
      await _.$axios.get('/projects?_sort=ProjectPriority').then((res) => {
        if (res) {
          _.projects = res.data;
        }
      });
    },
    async getTimeline() {
      let _ = this;
      await _.$axios.get('/project-milestones').then((res) => {
        if (res) {
          _.timeline = res.data;
        }
      });
    },
    async populateData() {
      for (let i = 0; i < this.timeline.length; i++) {
        if (this.timeline[i].Project != null) {
          this.dataTimeline.events.push({
            media: {
              url: await this.timeline[i].Project.Cover.formats.medium.url,
            },
            start_date: {
              month: await this.timeline[i].StartDate.substring(7, 5),
              day: await this.timeline[i].StartDate.substring(10, 8),
              year: await this.timeline[i].StartDate.substring(0, 4),
            },
            end_date: {
              month: await this.timeline[i].EndDate.substring(7, 5),
              day: await this.timeline[i].EndDate.substring(10, 8),
              year: await this.timeline[i].EndDate.substring(0, 4),
            },
            text: {
              headline: await this.timeline[i].Project.Name,
              text: await this.timeline[i].KeyActivity,
            },
          });
        }
      }
      new Timeline('timeline-landing', this.dataTimeline, this.options);
    },
  },
};
</script>
