<template>
  <div>
    <div id="wrapper" class="clearfix"></div>
    <header
      id="header"
      class="full-header header-size-custom"
      data-sticky-shrink="false"
      data-mobile-sticky="true"
    >
      <div id="header-wrap">
        <div class="container">
          <div class="header-row">
            <!-- Logo
            ============================================= -->
            <div id="logo" class="mr-lg-3">
              <a href="/" class="standard-logo"
                ><img
                  src="@/assets/biofarma/Picture12.png"
                  alt="Canvas Logo"
                  class="p-2"
              /></a>
              <a href="/" class="retina-logo"
                ><img src="@/assets/biofarma/Picture12.png" alt="Canvas Logo"
              /></a>
            </div>
            <!-- #logo end -->

            <div id="primary-menu-trigger">
              <svg class="svg-trigger" viewBox="0 0 100 100">
                <path
                  d="m 30,33 h 40 c 3.722839,0 7.5,3.126468 7.5,8.578427 0,5.451959 -2.727029,8.421573 -7.5,8.421573 h -20"
                ></path>
                <path d="m 30,50 h 40"></path>
                <path
                  d="m 70,67 h -40 c 0,0 -7.5,-0.802118 -7.5,-8.365747 0,-7.563629 7.5,-8.634253 7.5,-8.634253 h 20"
                ></path>
              </svg>
            </div>

            <!-- Primary Navigation
            ============================================= -->
            <!-- <nav class="primary-menu">
              <ul
                class="one-page-menu menu-container"
                data-easing="easeInOutExpo"
                data-speed="1250"
                data-offset="65"
              >
                <li class="menu-item">
                  <a href="#content" class="menu-link"><div>Agenda</div></a>
                </li>
                <li class="menu-item">
                  <a href="#" class="menu-link" data-href="#footer"
                    ><div>Kontak Kami</div></a
                  >
                </li>
              </ul>
            </nav> -->
            <!-- #primary-menu end -->
          </div>
        </div>
      </div>
      <div class="header-wrap-clone"></div>
    </header>
  </div>
</template>

<script>
export default {
  name: 'Header',
  data() {
    return {
      imgLogo: '@/assets/biofarma/logoholdong.png',
    };
  },
};
</script>
