import Vue from 'vue';
import Router from 'vue-router';

import Landing from '@/views/Landing';
import Projects from '@/views/Projects';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'landing',
      component: Landing,
      meta: {
        label: 'landing',
      },
    },
    {
      path: '/projects/:id',
      name: 'projects',
      component: Projects,
      meta: {
        label: 'projects',
      },
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return { selector: to.hash };
    }
    return { x: 0, y: 0 };
  },
});

export default router;
