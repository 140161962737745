import Vue from 'vue';
import App from './App.vue';
import router from './router';
import axios from 'axios';
import moment from 'moment';
import store from './store/store.js';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import VueMaterialTabs from 'vue-material-tabs';

Vue.config.productionTip = false;
const token =
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwiaWF0IjoxNjM4ODYyNDMyLCJleHAiOjE2NDE0NTQ0MzJ9.zQffPRZXPgEkYkmxKnZgOH9AGE7vwnOBt-V8_s7mPrw';
Vue.prototype.$axios = axios;
axios.defaults.baseURL = process.env.VUE_APP_API;
axios.defaults.headers.get['Content-Type'] = 'application/json';
axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
axios.interceptors.request.use(
  function (config) {
    store.commit('set_spinner', true);
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  function (response) {
    store.commit('set_spinner', false);
    return response;
  },
  function (error) {
    store.commit('set_spinner', false);
    if (error.response.status === 422) {
      alert('Kode 422,Terjadi kesalahan sistem, silahkan coba lagi');
    }

    if (error.response.status === 500) {
      alert('Kode 500,Terjadi kesalahan sistem, silahkan coba lagi');
    }

    return Promise.reject(error);
  }
);

Vue.filter('formatDate', function (value) {
  if (value) {
    return moment(String(value)).format('DD/MM/YYYY');
  }
});
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueMaterialTabs);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
