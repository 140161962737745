<template>
  <section id="footer" class="my-0">
    <footer id="footer" class="border-0">
      <div class="container clearfix">
        <div class="footer-widgets-wrap row clearfix">
          <div class="col-md-4 col-sm-6">
            <div class="widget clearfix">
              <div class="d-flex align-items-center mb-4">
                <h4
                  class="nott ls0 mb-0 font-body ml-2"
                  style="color:#ffffff"
                >
                  Holding BUMN Farmasi
                </h4>
              </div>

              <div class="d-flex clearfix">
                <div class="pr-4 pl-1">
                  <i class="icon-building2 h3" style="color: #ffffff;"></i>
                </div>
                <div
                  class="flex-grow-1"
                  style="color:#ffffff; text-align:left"
                >
                  <address>
                    <abbr> <strong>Headquarters</strong><br /> </abbr>
                    Jl. Pasteur No.28<br />
                    Bandung Jawa Barat Indonesia 40161<br />
                  </address>
                </div>
              </div>
              <div class="d-flex clearfix">
                <div class="pr-4 pl-1">
                  <i class="icon-call h3" style="color:#ffffff"></i>
                </div>
                <div
                  class="flex-grow-1"
                  style="color:#ffffff; text-align:left"
                >
                  <div class="bottommargin-sm">
                    <abbr><strong>Phone</strong><br /></abbr>
                    (+62) 22 2033755<br />
                    <abbr><strong>Fax</strong><br /></abbr> (+62) 22 2041306<br />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="copyrights" style="color: #ffffff;">
        <div class="container clearfix">
          <div class="row align-items-center">
            <div class="col-sm-6">
              Copyrights &copy; 2021 All Rights Reserved by IT Digital
              Healthcare
            </div>
          </div>
        </div>
      </div>
    </footer>
  </section>
</template>
<script>
export default {
  name: "Footer",
};
</script>
