<template>
  <transition name="fade">
    <div class="loader" v-show="$store.state.spinner">
      <img src="/images/spinner.svg">
    </div>
  </transition>
</template>

<script>
export default {
  name: 'Spinner',
};
</script>

<style lang="scss" scoped>
  .loader {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(255, 255, 255, .6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99999;
  }

  .loader img{
    width : 96px;
    height : 96px;
  }


</style>
