<style scoped>
div#timeline-embed {
  height: 600px;
}
</style>
<template>
  <div>
    <Header />
    <!-- Page Title
      ============================================= -->
    <section id="page-title-detail">
      <div class="container clearfix">
        <!-- <hr class="line"> -->
        <h1>{{ projectDetail.Name }}</h1>
        <!-- <span>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</span> -->
      </div>
    </section>
    <!-- #page-title end -->

    <!-- Content
      ============================================= -->
    <section id="content-detail">
      <div class="content-wrap">
        <div class="container clearfix detail">
          <div class="row">
            <!-- Portfolio Single Image
              ============================================= -->
            <div class="col-lg-12 portfolio-single-image">
              <a href="#"><img :src="cover" alt="Image" /></a>
            </div>
            <!-- .portfolio-single-image end -->

            <!-- Portfolio Single Content
              ============================================= -->
            <div class="col-lg-12 portfolio-single-content mt-5"></div>
          </div>
          <Tabs>
            <TabItem name="Description" class="mt-4">
              <div class="first-tab"><p v-html="descComputed"></p></div>
            </TabItem>
            <TabItem name="Business Process">
              <div class="second-tab"><p v-html="busComputed"></p></div>
            </TabItem>
            <TabItem name="Goals">
              <div class="second-tab"><p v-html="goalComputed"></p></div>
            </TabItem>
            <TabItem name="Impacts">
              <div class="second-tab"><p v-html="impactComputed"></p></div>
            </TabItem>
          </Tabs>
          <div class="timeline-title" style="margin-top: 30px">
            <h4 class="timeline-title">Milestones</h4>
          </div>
          <div id="timeline-landing" style="width: 100%; height: 800px"></div>
        </div>
      </div>
    </section>
    <!-- #content end -->
    <Footer />
  </div>
</template>

<script>
import Header from '../components/header.vue';
import Footer from '../components/Footer.vue';
import Spinner from '@/components/spinner';
import { Timeline } from '@knight-lab/timelinejs';
import '@knight-lab/timelinejs/dist/css/timeline.css';
import { marked } from 'marked';

export default {
  name: 'EventDetail',
  components: { Header, Footer, Spinner },

  data() {
    return {
      projectDetail: {
        Cover: {
          formats: {
            medium: {
              url: '',
            },
          },
        },
        id: null,
      },
      timeline: [],
      dataTimeline: {
        events: [],
      },
      id: null,
      params: {},
      options: {
        font: 'georgia-helvetica',
        initial_zoom: 2,
      },
    };
  },
  async created() {
    await this.getById();
  },
  mounted: function () {},
  computed: {
    descComputed: function () {
      if (this.projectDetail.Description) {
        // const doc = this.projectDetail.Description;
        // return doc.replace(/(?:\r\n|\r|\n)/g, '<br />');
        return marked.parse(this.projectDetail.Description);
      }
    },
    busComputed: function () {
      if (this.projectDetail.BusinessProcess) {
        return marked.parse(this.projectDetail.BusinessProcess);
      }
    },
    goalComputed: function () {
      if (this.projectDetail.Goals) {
        return marked.parse(this.projectDetail.Goals);
      }
    },
    impactComputed: function () {
      if (this.projectDetail.Impacts) {
        const doc = this.projectDetail.Impacts;
        return marked.parse(this.projectDetail.Impacts);
      }
    },
    cover: function () {
      if (this.projectDetail) {
        return this.projectDetail.Cover.formats.medium.url;
      }
    },
  },
  methods: {
    async getById() {
      let _ = this;
      await _.$axios.get('/projects/' + _.$route.params.id).then((res) => {
        if (res) {
          _.projectDetail = res.data;
          _.timeline = _.projectDetail.Project_Milestones;
          this.populateData();
        }
      });
    },
    populateData() {
      for (let i = 0; i < this.timeline.length; i++) {
        this.dataTimeline.events.push({
          media: {
            url: this.projectDetail.Cover.formats.medium.url,
          },
          start_date: {
            month: this.timeline[i].StartDate.substring(7, 5),
            day: this.timeline[i].StartDate.substring(10, 8),
            year: this.timeline[i].StartDate.substring(0, 4),
          },
          end_date: {
            month: this.timeline[i].EndDate.substring(7, 5),
            day: this.timeline[i].EndDate.substring(10, 8),
            year: this.timeline[i].EndDate.substring(0, 4),
          },
          text: {
            headline: this.timeline[i].KeyActivity,
            text: marked.parse(this.timeline[i].ActivityDescription),
          },
        });
      }
      console.log(this.dataTimeline);
      new Timeline('timeline-landing', this.dataTimeline, this.options);
    },
  },
};
</script>
