<template>
  <section
    id="slider"
    class="slider-element min-vh-100 include-header"
    style="
        background: url('images/biofarma/coverbaru.jpg') no-repeat center
          right;
        background-size: cover;
      "
  >
    <div class="slider-inner">
      <div class="vertical-middle">
        <div class="container py-5">
          <div class="row">
            <div class="col-lg-6">
              <h2
                class="ls0 font-weight-bold text-uppercase"
                style="font-size: 48px"
              >
                Program Vaksinasi
                <br />
                Covid-19
              </h2>
              <h3 class="font-weight-light" style="font-size: 20px">
                Dengan Program Vaksinasi Covid-19, mari bersama kita tuntaskan
                penyebaran pandemi Covid-19 untuk masyarakat Indonesia yang
                lebih aman, sehat dan produktif.
              </h3>
              <a
                href="#content"
                class="button button-3d button-rounded button-blue"
                data-scrollto="#section-about"
                data-offset="60"
                >Agenda<i class="icon-line-arrow-down"></i
              ></a>
            </div>
          </div>
        </div>
      </div>

      <div class="video-wrap d-lg-none">
        <div
          class="video-overlay"
          style="background: rgba(255, 255, 255, 0.8)"
        ></div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Slider",
};
</script>
