import 'es6-promise/auto'
import Vue from 'vue'
import Vuex from 'vuex'
import mutations from './mutations'

Vue.use(Vuex)

//=======vuex store start===========
const store = new Vuex.Store({
    state: {
        spinner : false,
    },
    mutations
})
//=======vuex store end===========
export default store
